import React, { ReactElement } from 'react';

import { Header } from '../components/Header';
import { Meta } from '../components/Meta';
import { PricingPayment } from '../components/pricing/PricingPayment';

interface Props { }

function Features(_props: Props): ReactElement {
  return (
    <>
      <Meta title="Bảng giá và nâng cấp dịch vụ cokhach.vn"
        description="Thêm nhiều khách, tiết kiệm ngân sách khi dùng các gói cước trả phí được thiết kế theo tăng trường kinh doanh của bạn | Bắt đầu sử dụng miễn phí!" />
      <Header isBgTransparent={true} />
      <PricingPayment />
      {/* <FaqPayment /> */}
    </>
  )
}

export default Features
